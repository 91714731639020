import path from 'path';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { home as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import BackgroundImage from 'gatsby-background-image';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    PersonalLoanForm,
    ScrollMarker,
    SEO,
    TrustIcons,
    Variants,
} from '@brandedholdings/react-components';
import SectionHero from '../components/landing-pages/sections/Hero';
import SectionCTA from '../components/landing-pages/sections/CTA';
import SectionFAQs from '../components/landing-pages/sections/FAQs';
import SectionRequirements from '../components/landing-pages/sections/Requirements';
import IconCash from '../images/icons/icon-cash.svg';
import IconIdea from '../images/icons/icon-idea.svg';
import IconLock from '../images/icons/icon-lock.svg';
import IconMagicStick from '../images/icons/icon-magic-stick.svg';
import IconSpeed from '../images/icons/icon-speed.svg';

import LogoCnn from '../images/logos/logo-cnn.svg';
import LogoFoxNews from '../images/logos/logo-fox-news.svg';
import LogoMsnbc from '../images/logos/logo-msnbc.svg';
import LogoVh1 from '../images/logos/logo-vh1.svg';
import LogoHallmarkChannel from '../images/logos/logo-hallmark-channel.svg';

import LogoAsSeenOnTV from '../images/logos/tv.svg';
import LogoAsHeardOnRadio from '../images/logos/as-heard-on-radio.svg';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: 'default',
            showRLA: true,
            finalLoanAmounts: {
                min: 0,
                max: 0
            },
        };
        this.setRLA = this.setRLA.bind(this);
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    setRLA(e,obj) {
        this.setState({
            finalLoanAmounts: {
                ...obj
            }
        }, () => {
            this.setupApplicationFocus(e);
        });
    }

    setupApplicationFocus(e) {
        if (this._personalLoanForm !== null) {
            e.preventDefault();
            ScrollMarker.scrollToMarker('heroStart');
            this._personalLoanForm.getWrappedInstance().focusApplication();
        }
    }

    componentDidMount() {
        const match = document.location.href.toLowerCase().match(/(([?&#])startnow=true|([?&#])promoid|([?&#])promo_id|([?&#])utm_source=revpie)/i);

        if (match instanceof Array) {
            this.setState({ variant: 'x' });
        }
    }

    render() {
        const page = this.props;
        this._personalLoanForm = null;
        const {
            finalLoanAmounts,
            showRLA,
        } = this.state;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const variants = new Variants()
        const enableDebtQuestions = variants.active.debt_questions && (variants.active.debt_questions === 'test');
        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        const maxLoanValue = 5000;
        const terms = "/terms.html";
        const privacy = "/privacy.html";
        const electronicDisclosure = "/terms.html#electronic-disclosure";
        const creditDisclosure = "/terms.html#credit-disclosure";
        const partners = "/partners.html";
        // const callCenterPhoneNumber = siteMeta.callCenter.phoneNumber || '';
        // const callCenterMessage = siteMeta.callCenter.message || '';
        // const clickToCallCenter = "tel:1" + siteMeta.callCenter.phoneNumber.replace(/-| |[()]/g, '');


        return (
            <PageWrapper onClick={this.setupApplicationFocus.bind(this)} landingPage="true">
                <SEO post={post} />
                <div className="hero__funnel">
                    <BackgroundImage
                        className="background__gatsby hero hero__alternative background__graphic"
                        fluid={pageImages.hero.image.fluid}
                    >
                    </BackgroundImage>
                    <BackgroundImage
                        className="hero background background__spokesperson text-shadow__blue headlines__bold hide-on-init"
                        fluid={pageImages.spokesperson.image.fluid}
                        style={{ backgroundColor: 'transparent' }}
                        durationFadeIn={0}
                        loading="eager"
                    >
                        <div className="layout">
                            <div className="hero--message">
                                <SectionHero
                                    setRLA={this.setRLA}
                                />
                                <div className="form__footer">
                                    {/* {callCenterPhoneNumber && (
                                        <a 
                                            href={clickToCallCenter}
                                            className="call-center"
                                        >
                                            <span className="call-center--message">{callCenterMessage + ' '}</span>
                                            <span className="call-center--phone">{callCenterPhoneNumber}</span>
                                        </a>
                                    )} */}
                                    <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <div className="background form--hidden">
                        <div className="layout">
                            <div className="form split-form">
                                <PersonalLoanForm
                                    minLoanValue={1000}
                                    maxLoanValue={maxLoanValue}
                                    modalDisclaimerText={siteMeta.modalDisclaimer}
                                    enableOverlay={true}
                                    ref={(personalLoanForm) => { this._personalLoanForm = personalLoanForm; }}
                                    trustIcons={['mcafee', 'secured']}
                                    urlTerms={terms}
                                    urlPrivacy={privacy}
                                    urlElectronicDisclosure={electronicDisclosure}
                                    urlCreditDisclosure={creditDisclosure}
                                    urlPartners={partners}
                                    checklistItem1={siteMeta.funnel.checklist.item1}
                                    checklistItem2={siteMeta.funnel.checklist.item2}
                                    checklistItem3={siteMeta.funnel.checklist.item3}
                                    depositInfoSubheading={siteMeta.funnel.depositInfo.subheading}
                                    depositInfoExpanderItem1Answer={siteMeta.funnel.depositInfo.expanderItem1.answer}
                                    depositInfoExpanderItem2Answer={siteMeta.funnel.depositInfo.expanderItem2.answer}
                                    depositInfoExpanderItem3Answer={siteMeta.funnel.depositInfo.expanderItem3.answer}
                                    depositInfoExpanderItem4Answer={siteMeta.funnel.depositInfo.expanderItem4.answer}
                                    initialLegalCertification={(
                                        <small>By submitting my information, I certify that I am a U.S. resident, at least 18 years of age, agree to the <Link to={privacy} tabIndex="-1" target="_blank">Privacy Policy</Link> and <Link to={terms} tabIndex="-1" target="blank">Terms of Web Site Use</Link>, and that I consent to receive offers from you and from third parties.</small>
                                    )}
                                    processingHeading="Lenders are reviewing your information."
                                    processingSubheading="If a lender is ready to work with you, you’ll be redirected to their&nbsp;website."
                                    secondChanceMessage1={siteMeta.secondChance.message.line1}
                                    secondChanceMessage2={siteMeta.secondChance.message.line2}
                                    resumeSubheading={(<span>When You Need It</span>)}
                                    disableCreditRatingStep
                                    enableProcessingWithOffer
                                    enableGoogleStreet
                                    showRLA={showRLA}
                                    requestedLoanAmountMin={finalLoanAmounts.min === 0 ? '' : finalLoanAmounts.min.toString()}
                                    requestedLoanAmountMax={finalLoanAmounts.max === 0 ? '' : finalLoanAmounts.max.toString()}
                                    enableTitleLoan
                                    enableDebtQuestions
                                    // callCenterPhoneNumber={callCenterPhoneNumber}
                                    // callCenterMessage={callCenterMessage}
                                />
                            </div>
                            <div className="form__footer">
                                <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollMarker name="getStartedReveal"/>
                <div className="badges__as-seen">
                    <LogoAsSeenOnTV />
                    <LogoAsHeardOnRadio />
                </div>
                <div className="asSeenOn">
                    <LogoCnn />
                    <LogoFoxNews />
                    <LogoMsnbc />
                    <LogoVh1 />
                    <LogoHallmarkChannel />
                </div>
                <div id="about-us" className="background background__white headlines__bold">
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header center">
                                <h2 className="heading__main">
                                    Life happens. {siteMeta.nameShort} Is Here to Help. 
                            </h2>
                            </div>
                            <div className="layout-content__body text-muted">
                                <p>
                                    Whether you find yourself with an unexpected bill, a necessary home or car repair, or many other types of financial need, Cash Happens is your trusted source to help connect you with reputable lenders in our trusted marketplace. Our network of lenders can provide financial assistance fast - often as soon as the next business day, and hassle-free.<sup>*</sup>
                                </p>
                                <br />
                            </div>
                            <div className="layout-content__body">
                                <div className="layout--3-column-split">
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconMagicStick className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                Fast &amp; Easy
                                            </h5>
                                                <p className="text-muted">
                                                Find a lender in minutes!
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="spacer" />
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconSpeed className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                Get up to $5,000
                                            </h5>
                                                <p className="text-muted">
                                                Cash is deposited directly into your account by a lender in as little as 24 hours!<sup>*</sup>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="spacer" />
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconLock className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                Peace of Mind
                                            </h5>
                                                <p className="text-muted">
                                                Your information is protected by the latest encryption technology.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BackgroundImage
                    className="background__laptop"
                    fluid={pageImages.laptoplarge.image.fluid}
                    style={{ backgroundSize: '0 0', backgroundPosition: 'center left' }}
                    id="howitworks"
                >
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header">
                                <h2 className="heading__main">
                                    How It Works
                                </h2>
                            </div>
                            <div className="layout-content__body">
                                <h4 className="font-weight-300">
                                    <IconIdea className="icon__color icon__inline" />
                                    Submit a Quick and Simple Online Form.
                                </h4>
                                <p>
                                    No storefronts to drive to, no lines to stand in. Submit your information completely online through our safe and secure form. Cash Happens never charges a fee for our service.
                                </p>
                                <br />
                                <h4 className="font-weight-300">
                                    <IconCash className="icon__color icon__inline" />
                                    Up to $5,000 in Your Account As Soon As Tomorrow!<sup>*</sup>
                                </h4>
                                <p>
                                    Once your information has been submitted, we will send it to our network of lenders who will then review it instantly. If a lender chooses to work with you, you will then be redirected to their website and presented with an offer. There, you will have an opportunity to review the exact terms of the loan, and if you accept, your money could be transferred directly to your account in as little as 24 hours.<sup>*</sup>
                                </p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="background background__graphic text-shadow__blue headlines__bold"
                    fluid={pageImages.hero.image.fluid}
                >
                    <SectionCTA />
                </BackgroundImage>
                <div className="background background__white headlines__bold">
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header center">
                                <h2 className="heading__main">
                                    What You&rsquo;ll Need
                                </h2>
                            </div>
                            <div className="layout-content__body">
                                <SectionRequirements />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="questions" className="background background__extra-light-gray headlines__bold alternative">
                    <div className="layout layout--narrow">
                        <div className="layout-content__header">
                            <h2 className="heading__main center">
                                Common Questions
                        </h2>
                        </div>
                        <div className="layout-content__body">
                            <SectionFAQs />
                        </div>
                    </div>
                </div>
                <BackgroundImage
                    className="background background__graphic text-shadow__blue headlines__bold"
                    fluid={pageImages.hero.image.fluid}
                >
                    <SectionCTA howMuch />
                </BackgroundImage>
            </PageWrapper>
        );
    }
};

Home.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default Home;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;
